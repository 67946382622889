<template>
  <v-app>
   <v-container class="pa-3" style="max-width: 550px">
     <v-row justify="space-around">
       <v-col xs="12" sm="12" md="12"
              class="pa-0 ma-0 position-fixed" style="z-index: 9999; max-width: 550px">
         <div>
           <div style="height: 50px" class="bg-black position-relative">
             <div v-if="isHistory"
                  class="ml-2 position-absolute"
                  style="top:50%;transform: translateY(-50%);cursor: pointer"
                  @click="goBack">
               <v-icon icon="$arrowLeft" />
             </div>
             <div class="position-absolute" style="height:40px; left:50%;top:50%;transform: translateY(-50%) translateX(-50%)" >
              <img src="./assets/logo.svg"
                   class="h-100"
                   style="cursor:pointer;"
                   @click="goHome" >
             </div>
           </div>
         </div>
       </v-col>
     </v-row>
     <v-row justify="space-around" style="margin-top: 50px" >
       <v-col xs="12" sm="12" md="12"
              class="pa-0 ma-0 " style="max-width: 600px;min-height: 300px;">
          <router-view />
           <div class="bg-black pa-3" style="color: #FFFFFF;font-size: 14px">
             법인명 : 주식회사 위얼라이브 | 제호 : 유얼라이브(ualive)
             <br>등록번호 : 서울 아53541 ㅣ등록일 : 2021-02-08 ㅣ발행일 : 2021-09-13
             <br>주소 : 서울특별시 마포구 독막로2길 31, 4층
             <br>대표전화 : 02-336-0657 ㅣ팩스 : 02-336-0657
             <br>발행인 : 김형태 ㅣ편집인 : 이은희 ㅣ청소년보호책임자 : 이은희
           </div>
       </v-col>
     </v-row>
<!--     <v-row justify="space-around">
       <v-col xs="12" sm="6" lg="5" class="pa-0 ma-0 position-absolute">

       </v-col>
     </v-row>-->
   </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  computed:{
    isHistory(){
      return (window.history.length > 1 && this.$route.path !== '/');
    }
  },
  methods:{
    goBack () {
      this.isHistory
          ? this.$router.go(-1)
          : this.$router.push('/')
    },
    goHome(){
      this.$router.push('/')
    }
  },
}
</script>

<style>
</style>
