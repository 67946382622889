import {createApp} from 'vue'
import App from './App.vue'

import router from "./router/index.js";
import vuetify from "@/plugins/vuetify";
import loadScript from 'vue-plugin-load-script'


const app = createApp(App);
app.use(router);
app.use(vuetify);
app.use(loadScript);
app.mount('#app');


