import {createRouter, createWebHistory} from "vue-router";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home'), // 동적 import
  },
  {
    path: '/post/:id',
    name: 'PostDetail',
    component: () => import('@/views/PostDetail'), // 동적 import
    props: true,
  },
  {
    path: '/news',
    name: 'NewsAll',
    component: () => import('@/views/NewsAll'), // 동적 import
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(),
  base: '/',
  routes,
});


export default router;
